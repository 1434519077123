import React from 'react'
import '../styles/view404.css'
import { Link } from "react-router-dom"
import Button from 'react-bootstrap/Button';
export default function NotFound() {
    return (
        <div className="todo404">
            <div className="containerH1Style">
                <h1 className="h1Style">Esta dirección no existe</h1>
            </div>
            <div className="botonContainer404">
                <Link to="/">
                    <Button variant="light" style={{ color: '#037BFF' }} >Ir a Holu</Button >
                </Link>
            </div>
        </div>
    )
}