import React from 'react'
import Navbar from '../components/appNavbar'
import '../styles/horasLibres.css'
import '../styles/configuracion.css'
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import CambiarPass from './cambiarPass'
import CambiarUsuario from './cambiarUsuario'
//import ImportarHorario from './importarHorario'
import { Link, Redirect } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../connectionApi'
import authandler from '../authandler'
class Configuracion extends React.Component {
    state = {
        accion: 0,
        AInicio: false
    }
    async componentDidMount() {
        this.setState({ AInicio: false })
    }
    cambiarPass = async () => {
        let status = await api.CambiarPass()
        if (status === "Done!") {
            this.notify("Podrás cambiar la contraseña desde el link que enviamos a tu correo.")
        } else {
            this.notify("No se pudo cambiar la contraseña")
        }
    }
    closeSession = async () => {
        this.setState({ AInicio: true })
        authandler.borrar()
    }
    notify = (mensaje) => {
        toast(mensaje)
    }
    render() {
        if (this.state.AInicio) {
            return <Redirect to='/' />
        } else {
            return (
                <div className="generalHorasLibres">
                    <ToastContainer />
                    <div className="navbarContainerHorasLibres">
                        <Navbar />
                    </div>
                    <div className="coreSectionHorasLibres">
                        <div className="seccionCore">
                            <div className="containerTituloIcono">
                                <SettingsIcon style={{ color: '#FFFFFF', fontSize: 45 }} />
                            </div>
                            {this.state.accion !== 0 ?
                                < div >
                                    <IconButton aria-label="search" onClick={() => { this.setState({ accion: 0 }) }}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </div>
                                :
                                <div className="laLista">
                                    {/* <div className="listItemHora" >
    
                                        <div onClick={() => { this.setState({ accion: 2 }) }} >
                                            <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 18, textAlign: 'center' }} >Cambiar nombre</p>
                                        </div>
    
                                    </div>
                                    <div className="listItemHora" >
                                        <div onClick={() => { this.setState({ accion: 3 }) }}>
                                            <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 18, textAlign: 'center' }}>Importar tu horario</p>
                                        </div>
                            </div>*/}
                                    <div className="listItemHora" >
                                        <div onClick={() => { this.cambiarPass() }}>
                                            <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 18, textAlign: 'center' }}>Cambiar contraseña</p>
                                        </div>
                                    </div>
                                    <div className="listItemHora" >
                                        <Link to="/acerca">
                                            <div>
                                                <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 18, textAlign: 'center' }}>Acerca de holu</p>
                                            </div>
                                        </Link>

                                    </div>
                                    <div className="listItemHora" >
                                        <div onClick={() => { this.closeSession() }}>
                                            <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 18, textAlign: 'center' }}>Cerrar sesión</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.accion === 2 ? <CambiarUsuario cambiarUsuario={this.changeName} /> : null)}
                        </div>
                    </div>
                </div >
            )
        }

    }
}
export default Configuracion