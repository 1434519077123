import React from 'react';
import{ Search }from '@material-ui/icons';
import '../styles/firstView.css'
export default class Register extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="divImage">
                    <img src={require('../assets/holu-logo-type-blanco.png')} className="laImagen" alt="holu"></img>
                </div>
                <div className="divTexto1">
                    <div style={{ paddingTop: 3 }}>
                        <Search style={{ color: 'white' }}></Search>
                    </div>
                    <h4 className="textoBlanco">Descubre quienes están libres </h4>
                </div>

                <div className="divImage2">
                    <img src={require('../assets/holu-logo-icono-blanco.png')} className="laImagen" alt="holu"></img>
                </div>
            </React.Fragment>
        )
    }
}
