import theHandler from '../handlerState'
import authandler from '../authandler'
const Base_api = theHandler.server()
async function elCorreo() {
    const elCorreo = await authandler.verifyExpress()
    return elCorreo
}
class Api {
    async register(userU, passU, name, passApp) {//listo
        try {
            let requestOptions = {
                method: 'POST',
                redirect: 'follow',
                mode: 'cors',
                body: JSON.stringify({
                    userU, passU, name, passApp
                }),
                headers: { 'Content-Type': 'application/json', }
            }
            let query = await fetch(`${Base_api}completeRegister/`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        }
        catch (error) {
            console.log("Esto es un error atrapado en la función register de la clase api: " + error)
        }
    }
    async login(user, pass) {//listo
        let correo = user + "@uninorte.edu.co"
        let passs = pass.pass
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                correo, passs
            }),
            headers: { 'Content-Type': 'application/json', }
        }
        try {
            let query = await fetch(`${Base_api}login/`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función login de la clase api: " + err)
        }
    }
    async searchUser(user) {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}buscarUsuarioPorNombre/${user}/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función searchUser de la clase api: " + err)
        }
    }
    async sendFriendRequest(userReceiver, user) {//listo
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}enviarSolicitudDeAmistad/${userReceiver}/${user}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función sendFriendRequest de la clase api: " + err)
        }
    }
    async retreiveFriendRequests() {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}traerSolicitudesDeAmistad/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función retreiveFriendRequests de la clase api: " + err)

        }
    }
    async traerAmigos(numero) {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            let query = await fetch(`${Base_api}traerAmigos/${numero}/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función traerAmigos de la clase api: " + err)
        }
    }
    async traerNumeroDeAmigos() {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            let query = await fetch(`${Base_api}traerNumeroDeAmigos/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función searchUser de la clase api: " + err)
        }
    }
    async eliminarAmigo(amigo) {//listo
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            };
            await fetch(`${Base_api}eliminarAmigo/${amigo}/${await elCorreo()}`, requestOptions)
        } catch (err) {
            console.log("Esto es un error atrapado en la función eliminarAmigo de la clase api: " + err)
        }
    }
    async CambiarPass() {//listo
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}cambiarPassword/${await elCorreo() + "@uninorte.edu.co"}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función eliminarAmigo de la clase api: " + err)
        }
    }
    async CambiarPass2(correo) {//when not logged
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}cambiarPassword/${correo + "@uninorte.edu.co"}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función eliminarAmigo de la clase api: " + err)
        }
    }
    async aceptarSolicitud(userSender) {//listo
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}aceptarSolicitud/${userSender}/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función eliminarAmigo de la clase api: " + err)
        }
    }
    async rechazarSolicitud(userSender) {//listo
        try {
            let requestOptions = {
                method: 'PATCH',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}rechazarSolicitud/${userSender}/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función eliminarAmigo de la clase api: " + err)
        }
    }
    async traerHorasLibres() {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            let query = await fetch(`${Base_api}traerHorasLibres/${await elCorreo()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función traerHorasLibres de la clase api: " + err)
        }
    }
    async traerAmigosLibresYa(user) {//listo
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            let fecha = new Date()
            let query = await fetch(`${Base_api}traerAmigosDiaHora/${user}/${fecha.getDay() - 1}/${fecha.getHours()}/${fecha.getMinutes()}`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función traerHorasLibres de la clase api: " + err)
        }
    }
    /*async colectivo() {
        try {
            const user = await elCorreo()
            let requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'user': user }
            }
            let query = await fetch(`${Base_api}colectivo/`, requestOptions)
            let queryJson = await query.json()
            return queryJson
        } catch (err) {
            console.log("Esto es un error atrapado en la función colectivo de la clase api: " + err)
        }
    }*/
}
export default new Api();