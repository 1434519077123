import React from 'react'
import '../styles/about.css'
export default class About extends React.Component {
    render() {
        return (
            <div className="todoAbout">
                <div className="filaUno">
                    <div className="containerLogoTitulo">
                        <img src={require('../assets/holu-logo-completo.png')} className="logoTitulo" alt="holu"></img>
                    </div>
                </div>
                <div className="filaDos">
                    <div>
                        <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 20, lineHeight: '1.5em', textAlign: 'center' }}>Holu es una plataforma creada y desarrollada por <a href="https://github.com/JaimeRamos99" target="_blank" rel="noopener noreferrer" style={{ color: '037BFF' }}>Jaime Ramos</a> y deplegada por <a href="https://github.com/sjdonado" target="_blank" rel="noopener noreferrer" style={{ color: '037BFF' }}>Juan Rodriguez</a></p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 20, lineHeight: '1.5em', textAlign: 'center' }}>Holu será la red social universitaria más grande del mundo.</p>
                    </div>
                </div>
                <div className="filaTres">
                    <h3 className="titulito">Acerca de tus datos y funcionamiento</h3>

                </div>
                <div className="filaCuatro">
                    <div>
                        <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 17, lineHeight: '1.5em', textAlign: 'center' }}>1- holu utliza Web scrapping para obtener tu horario de uninorte.</p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 17, lineHeight: '1.5em', textAlign: 'center' }}>2- holu NO almacena tu usuario ni contraseña uninorte.</p>
                    </div>
                    <div>
                        <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 17, lineHeight: '1.5em', textAlign: 'center' }}>3- holu no comercializa ni da tus datos a externos.</p>
                    </div>
                </div>

            </div>
        )
    }
}