import React from 'react';
import '../styles/about.css'
export default function PoliticaDeDatos(props) {
    return (
        <div className="todoAbout">
            <div className="filaUnoP">
                <div className="containerLogoTitulo">
                    <img src={require('../assets/holu-logo-completo.png')} className="logoTitulo" alt="holu"></img>
                </div>
            </div>
            <div className="filaDosP">
                <div>
                    <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 20, lineHeight: '1.5em', textAlign: 'center' }}>holu solo almacena tu usuario Uninorte, tu contraseña escogida para holu y las horas del día en las que estás en clase. tu contraseña para holu es almacenada por un provedor de autorización externo (producto de google), a esta contraseña no tenemos acceso directo.</p>
                </div>
                <div>
                    <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 20, lineHeight: '1.5em', textAlign: 'center' }}>En caso de querer borrar tus datos de la plataforma, debes solicitarle al administrador por medio de un correo.</p>
                </div>
            </div>

            <div className="filaCuatroP">
                <div>
                    <p style={{ fontFamily: "Andale Mono, monospace", fontSize: 17, lineHeight: '1.5em', textAlign: 'center' }}>Tu contraseña Uninorte solo se utiliza UNA SOLA VEZ, para extraer tu horario. Te recomendamos utilizar una contraseña diferente a la de tu cuenta Uninorte para registrarte en holu.</p>
                </div>
            </div>

        </div>
    )
}