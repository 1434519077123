import React from 'react';
import { Divider, IconButton, Paper, makeStyles } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsIcon from '@material-ui/icons/Settings';
//import Badge from '@material-ui/core/Badge';
import '../styles/appNavbar.css'
import { Link } from "react-router-dom"
const useStyles = makeStyles(() => ({
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 38,
        margin: 4,
        color: 'white'
    },
}));

export default function CustomizedInputBase() {
    const classes = useStyles();
    return (
        <Paper component="form" className="root" style={{ backgroundColor: '#037BFF' }}>
            <div className="leftSpaceNavbar">
                <Link to="/home">
                    <div className="iconoDeHoluContainer">
                        <img src={require('../assets/holu-logo-completo-blanco.png')} className="iconoDeHolu" alt=""></img>
                    </div>
                </Link>

            </div>
            <Divider className={classes.divider} orientation="vertical" style={{ backgroundColor: "white" }} />
            <Link to="/notifications">
                <IconButton style={{ color: "white" }} className={classes.iconButton} aria-label="directions">
                    {/* <Badge badgeContent={4} color="secondary">*/}
                    <NotificationsNoneIcon />

                    {/*</Badge>*/}
                </IconButton>
            </Link>

            <Divider className={classes.divider} orientation="vertical" style={{ backgroundColor: "white" }} />
            <Link to="/home">
                <IconButton style={{ color: "white" }} className={classes.iconButton} aria-label="directions" >
                    <PersonOutlineIcon />
                </IconButton>
            </Link>

            <Divider className={classes.divider} orientation="vertical" style={{ backgroundColor: "white" }} />
            <Link to="/configuracion">
                <IconButton style={{ color: "white" }} className={classes.iconButton} aria-label="directions">
                    <SettingsIcon />
                </IconButton>
            </Link>
        </Paper>
    );
}