import React from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Button from 'react-bootstrap/Button';
function getModalStyle() {
    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '60%',
        height: '30%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        boxShadow: theme.shadows[10],
        display: 'flex',
        justifyContent: 'center',
        minWidth: 290
    },
}));

export default function SimpleModal(props) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseEliminar = () => {
        props.borrar(props.usuario)
        setOpen(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <IconButton onClick={handleOpen}>
                <CancelIcon style={{ color: '#FA2A00' }} />
            </IconButton>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                            <h3 style={{ textAlign: 'center' }} id="simple-modal-title">¿Quieres eliminar a {props.usuario} de tu amigos?</h3>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                            <div style={{ paddingRight: 20 }}>
                                <Button variant="danger" onClick={() => { handleCloseEliminar() }}>Eliminar</Button>
                            </div>
                            <div>
                                <Button variant="secondary" onClick={() => { handleClose() }}>Cancelar</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    );
}