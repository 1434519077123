import React from 'react'
import Navbar from '../components/appNavbar'
import '../styles/horasLibres.css'
import '../styles/configuracion.css'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CambiarPass from './cambiarPass'
import 'react-toastify/dist/ReactToastify.css';
class NewPass extends React.Component {
    render() {
        return (
            <div className="generalHorasLibres">
                <div className="navbarContainerHorasLibres">
                    <Navbar />
                </div>
                <div className="coreSectionHorasLibres">
                    <div className="seccionCore">
                        <div className="containerTituloIcono">
                            <VpnKeyIcon style={{ color: '#FFFFFF', fontSize: 45 }} />
                        </div>
                        <CambiarPass />
                    </div>
                </div>
            </div >
        )
    }
}
export default NewPass