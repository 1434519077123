import React from 'react'
import '../styles/home.css'
import Navbar from '../components/appNavbar'
import { Avatar, IconButton, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Button, Spinner } from 'react-bootstrap'
import Popper from './popper'
import { debounce } from 'lodash'
import api from '../connectionApi'
import shortid from 'shortid'
import ContainerBotones from './containerButtonsHome'
import SmallPopper from './smallPopper'
import { ToastContainer, toast } from 'react-toastify'
import authandler from '../authandler'
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            showingPopper: false,
            showingPopper2: false,
            suggestions: [],
            solicitudesEnviadas: [],
            amigosLibresYa: []
        }
        this.handleInputThrottled = debounce(this.handlerSearch, 1000)
        this.handleInputThrottled2 = debounce(this.handlerSearch2, 1000)
    }
    state = {
        typing: false,
        typing2: false,
        busqueda: "",
        loading: true
    }
    async componentDidMount() {
        this.setState({ loading: true })
        let user = await authandler.verifyExpress()
        if (user) {
            let x = await api.traerAmigosLibresYa(user)
            if (x !== "no clases" && x !== "error") {
                this.setState({ amigosLibresYa: x })
            }
        }
        this.setState({ loading: false })
    }
    handlerSearch = async () => {
        this.setState({ typing: true })
        if (this.state.busqueda) {
            let suggestions = await api.searchUser(this.state.busqueda.toLowerCase())
            this.setState({ suggestions: suggestions })
        } else {
            this.setState({ showingPopper: false, suggestions: [] })
        }
        this.setState({ typing: false })
    }
    handlerSearch2 = async () => {
        this.setState({ typing2: true })
        if (this.state.busqueda) {
            let suggestions = await api.searchUser(this.state.busqueda.toLowerCase())
            this.setState({ suggestions: suggestions })
        } else {
            this.setState({ showingPopper2: false })
        }
        this.setState({ typing2: false })
    }
    superiorHandler = (event) => {
        this.setState({ showingPopper: true })
        this.setState({ busqueda: event.target.value })
        this.handleInputThrottled()
    }
    superiorHandler2 = (event) => {
        this.setState({ showingPopper2: true })
        this.setState({ busqueda: event.target.value })
        this.handleInputThrottled2()
    }
    shutDownPopper = () => {
        this.setState({ showingPopper: false })
    }
    shutDownPopper2 = () => {
        this.setState({ showingPopper2: false })
    }
    notify = (mensaje) => toast(mensaje);
    addFriend = async (idReceiver) => {
        if (!this.state.solicitudesEnviadas.includes(idReceiver)) {
            let respaldo = this.state.solicitudesEnviadas
            respaldo.push(idReceiver)
            this.setState({ solicitudesEnviadas: respaldo })
            let user = await authandler.verifyExpress()
            if (user) {
                await api.sendFriendRequest(idReceiver, user)
                this.notify("¡Se ha enviado tu solicitud de amistad!")
            }
        }
    }
    render() {
        return (
            <div className="todo" >
                <ToastContainer />
                <div className="navbarContainer">
                    <Navbar />
                </div>


                {/* contenedor de la barra de busqueda para pantallas pequeñas*/}
                <div className="upContainer">
                    <div className="inputSearchContainerUP">
                        <div className="input">
                            <InputBase
                                placeholder="Buscar Usuarios uninorte"
                                inputProps={{ 'aria-label': 'search users' }}
                                onChange={(event) => { this.superiorHandler2(event) }}
                            />
                        </div>
                        {this.state.typing2 ?
                            <div className="icon-container">
                                <i className="loader"></i>
                            </div>
                            :
                            <div className="containerBotonBuscar">
                                <IconButton type="submit" aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        }
                    </div>
                </div>

                {/* detecta cuando la lista de sugerencia de usuarios se está mostrando para pantallas pequeñas*/}
                {this.state.showingPopper2 ?
                    <div className="containerListSuggestionsUP">
                        <SmallPopper onClosePopper={this.shutDownPopper2} grande={false}>
                            {this.state.suggestions.length > 0 ? this.state.suggestions.map(object =>
                                < div key={shortid.generate()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                                    <h6>{object.nombre}</h6>
                                    <Button variant="success" size="sm" onClick={() => { this.addFriend(object.usuario) }}>Agregar</Button>
                                </div>
                            )
                                :
                                < div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                                    {this.state.typing2 ? <h6>Buscando...</h6> : <h6>No se encontraron resultados</h6>}
                                </div>
                            }
                        </SmallPopper>
                    </div>
                    :
                    null
                }

                {/* la lista donde se listan lo amigos disponibles en ese momento*/}
                <div className="listaContainer">
                    <div className="tituloHome">
                        <div className="containerH4">
                            <h4 style={{ fontFamily: "Andale Mono, monospace bold", textAlign: 'center' }}>Disponibles ahora</h4>
                        </div>
                        <div style={{ display: 'flex', textAlign: 'center' }}>
                            <span className="dot"></span>
                        </div>
                    </div>
                    <div className="containerList" id="barra">
                        {
                            (this.state.loading)
                                ?
                                <div style={{ color: '#037BFF', display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                                    <Spinner animation="border" role="status" style={{ color: '#037BFF' }}></Spinner>
                                </div>
                                :
                                (this.state.amigosLibresYa.length) > 0 ?

                                    this.state.amigosLibresYa.map(
                                        obj =>
                                            <div className="listItem" key={shortid.generate()}>
                                                <div className="contenedorAvatar">
                                                    <Avatar alt="">UN</Avatar>
                                                </div>
                                                <div className="containerP">
                                                    <span style={{ fontFamily: "Andale Mono, monospace", fontSize: 20 }}>{obj}</span>
                                                </div>
                                            </div>
                                    )
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span style={{ fontFamily: "Andale Mono, monospace", fontSize: 20 }}>No tienes amigos libres ahora</span>
                                    </div>
                        }

                    </div>
                </div>



                <div className="buttonsContainer">
                    {/*barra de busqueda de usuarios para dispositivos grandes*/}
                    <div className="inputSearchContainer">
                        <div className="input">
                            <InputBase
                                placeholder="Buscar Usuarios uninorte"
                                inputProps={{ 'aria-label': 'search users' }}
                                onChange={(event) => { this.superiorHandler(event) }}
                            />
                        </div>
                        {this.state.typing ?
                            <div className="icon-container">
                                <i className="loader"></i>
                            </div>
                            :
                            <div className="containerBotonBuscar">
                                <IconButton type="submit" aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </div>}
                    </div>

                    {/* detecta si se están mostrando sugerencias para dispositivos grandes*/}
                    {this.state.showingPopper ?
                        <div className="containerListSuggestions">
                            <Popper onClosePopper={this.shutDownPopper}>
                                {this.state.suggestions.length > 0 ? this.state.suggestions.map(object =>
                                    < div key={shortid.generate()} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                                        <h6>{object.nombre}</h6>
                                        {this.state.solicitudesEnviadas.includes(object.usuario)
                                            ?
                                            <Button variant="secondary" size="sm" onClick={() => { this.addFriend(object.usuario) }}>Solicitud enviada</Button>
                                            :
                                            <Button variant="success" size="sm" onClick={() => { this.addFriend(object.usuario) }}>Agregar</Button>
                                        }
                                    </div>
                                ) :
                                    < div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                                        {this.state.typing ? <h6>Buscando...</h6> : <h6>No se encontraron resultados</h6>}
                                    </div>
                                }
                            </Popper>
                        </div>
                        :
                        null
                    }


                    {/*contenedor de los botones*/}
                    <ContainerBotones />
                </div>

            </div >
        )
    }
}
export default Home