import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import InstagramIcon from '@material-ui/icons/Instagram';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom"
import '../styles/footer.css';
const FooterPage = () => {
    return (
        <MDBFooter className="font-small pt-1 mt-4">

            <div className="footer-copyright text-center py-3" id="footer-container">
                <MDBContainer fluid>
                    <MDBRow style={{ paddingBottom: 10 }}>
                        <MDBCol>
                            <h5 style={{ fontFamily: 'Roboto' }}>Contacto</h5>
                            <div >
                                <p style={{ fontFamily: 'Roboto' }}>+57 300 4071328</p>

                            </div>
                        </MDBCol>
                        <MDBCol>
                            <h6 style={{}}>¡Síguenos en instagram!</h6>
                            <a href="https://www.instagram.com/holuglobal/" target="_blank" rel="noopener noreferrer" style={{ paddingRight: 15 }}><InstagramIcon ></InstagramIcon></a>
                        </MDBCol>

                    </MDBRow>
                    <Divider variant="middle" />
                    <MDBRow style={{ paddingBottom: 10, paddingTop: 20 }}>
                        <MDBCol>
                            <Link to="/acerca">
                                <h6>Acerca de holu</h6>
                            </Link>
                        </MDBCol>
                        <MDBCol>
                            <Link to="/politicas">
                                <h6>Políticas de tratamiento de datos y privacidad</h6>
                            </Link>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </MDBFooter>

    );
}

export default FooterPage;