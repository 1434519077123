import React from 'react'
import Navbar from '../components/appNavbar'
import '../styles/horasLibres.css'
import '../styles/verAmigos.css'
import api from '../connectionApi'
import shortid from 'shortid'
import Avatar from '@material-ui/core/Avatar'
import ModalDeleteFriend from '../components/modalDeleteFriend'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
class VerAmigos extends React.Component {
    state = {
        modalOPen: false,
        numero: 0,//numero de abajo
        amigos: [],//este arreglo tendrá todos los amigos que se han traído hasta el momento
        loading: false,
        theTenSelected: [],
        numeroDeAmigos: 0 //este es el numero de amigos total del usuario en toda la app
    }
    async componentDidMount() {
        this.setState({ loading: true })
        let n = await api.traerNumeroDeAmigos()
        let r = await api.traerAmigos(this.state.numero)
        this.setState({ amigos: r[0].amigos, loading: false, theTenSelected: r[0].amigos, numeroDeAmigos: n })
        this.setState({ loading: false })
    }
    traerMasAmigos = async () => {
        if (this.state.theTenSelected.length === 10 && this.state.numero < this.state.numeroDeAmigos - 10) {//que la pagina que se está viendo esté llena, es decir que tenga 10 y que el limite inferior 
            this.setState({ loading: true })
            let x = await api.traerAmigos(this.state.numero + 10)
            this.setState({ theTenSelected: x[0].amigos, numero: this.state.numero + 10, loading: false })
            let array1 = this.state.amigos //cada vez que busco más amigos, "sumo" los nuevos al vector de amigos
            let array2 = array1.concat(x[0].amigos)
            this.setState({ amigos: array2 })
        }
    }
    mostrarAnteriores = () => {
        if (this.state.amigos.length > 10 && this.state.numero >= 10) {//si va para atrás siempre habrá una página llena
            let arreglo = []
            for (let i = this.state.numero - 10; i <= this.state.numero - 1; i++) {
                arreglo.push(this.state.amigos[i])
            }
            this.setState({ theTenSelected: arreglo, numero: this.state.numero - 10 })
        }
    }
    notify = (mensaje) => toast(mensaje);
    eliminarAmigo = async (user) => {
        this.setState({ loading: true })
        await api.eliminarAmigo(user)
        this.notify("Se ha eliminado a " + user + " de tus amigos")
        let n = await api.traerNumeroDeAmigos()
        let r = await api.traerAmigos(this.state.numero)
        this.setState({ amigos: r[0].amigos, loading: false, theTenSelected: r[0].amigos, numeroDeAmigos: n })
    }
    render() {
        return (
            <div className="generalHorasLibres">
                <ToastContainer />
                <div className="navbarContainerHorasLibres">
                    <Navbar />
                </div>
                <div className="coreSectionHorasLibres">
                    <div className="seccionUtil">
                        <div className="tituloContainerMisAmigos">
                            <h2 style={{ fontFamily: "Andale Mono, monospace bold", color: '#037BFF' }}>Mis amigos ({this.state.numeroDeAmigos})</h2>
                        </div>
                        {(this.state.loading)
                            ?
                            <div className="containerAmigos" id="barra">
                                <div style={{ color: '#037BFF', display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                                    <Spinner animation="border" role="status" style={{ color: '#037BFF' }}></Spinner>
                                </div>

                            </div>
                            :
                            <div className="containerAmigos" id="barra">
                                {(this.state.amigos.length > 0) ? this.state.theTenSelected.map(obj =>
                                    <div className="listItem" key={shortid.generate()}>
                                        <div className="containerLeftListItem">
                                            <div className="contenedorAvatar2">
                                                <Avatar alt="">UN</Avatar>
                                            </div>
                                            <div className="containerP">
                                                <span style={{ fontFamily: "Andale Mono, monospace", fontSize: 20 }}>{obj}</span>
                                            </div>
                                        </div>
                                        <div >
                                            <ModalDeleteFriend borrar={this.eliminarAmigo} usuario={obj} />
                                        </div>
                                    </div>
                                )
                                    :
                                    <div className="listItem">
                                        <h3 style={{ textAlign: 'center' }}>Aún no tienes amigos</h3>
                                    </div>
                                }

                            </div>
                        }

                        <div className="containerButtonsPagination">
                            <IconButton aria-label="search" className="butonsBackground" style={{ WebkitBoxShadow: '3px 3px 16px 3px rgba(236,235,232,1)', MozBoxShadow: '3px 3px 16px 3px rgba(236,235,232,1)', boxShadow: ' 3px 3px 16px 3px rgba(236,235,232,1)', marginRight: 40 }} onClick={() => { this.mostrarAnteriores() }}>
                                <NavigateBeforeIcon />
                            </IconButton>

                            <IconButton aria-label="search" className="butonsBackground" style={{ WebkitBoxShadow: '3px 3px 16px 3px rgba(236,235,232,1)', MozBoxShadow: '3px 3px 16px 3px rgba(236,235,232,1)', boxShadow: ' 3px 3px 16px 3px rgba(236,235,232,1)' }} onClick={() => { this.traerMasAmigos() }}>
                                <NavigateNextIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default VerAmigos