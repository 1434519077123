import React from "react";
import ReactDOM from 'react-dom';
import '../styles/popper.css'
export default class SimplePopper extends React.Component {

    state = {
        showing: false
    }
    componentWillMount() {
        document.addEventListener('click', this.handleClick, false);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }
    turnOn = () => {

    }
    handleClick = e => {
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.props.onClosePopper()
        }
    }
    render() {
            return (
                <div className="todoPopper">
                    {this.props.children}
                </div>
            )
    }
}