import React from 'react';
import FirstView from './components/firstView'
import Register from './components/register'
import Home from './components/home'
import HorasLibres from './components/horasLibres'
import VerAmigos from './components/verAmigos'
import Configuracion from './components/configuracion'
import About from './components/about'
import NotFound from './components/view404'
import NotLoggedIn from './components/notLoggedIn'
import Bienvenido from './components/bienvenido'
import Notifications from './components/notifications'
import Footer from './components/footer'
import Politicas from './components/politicaDeDatos'
//import Colectivo from './components/colectivos'
import authandler from './authandler'
import NewPass from './components/newPass'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom"
function App() {
    return (
        <div>
            <Router>
                <Switch>
                    <PrivateRouteFirstView exact path="/">
                        <FirstView />
                    </PrivateRouteFirstView>
                    <Route exact path="/register">
                        <Register />
                    </Route>
                    <Route exact path="/acerca">
                        <About />
                    </Route>
                    <Route exact path="/bienvenido">
                        <Bienvenido />
                    </Route>
                    <Route exact path="/denied">
                        <NotLoggedIn />
                    </Route>
                    <Route exact path="/politicas">
                        <Politicas />
                    </Route>
                    <Route exact path="/newPass">
                        <NewPass />
                    </Route>
                    <PrivateRoute path="/horasLibres">
                        <HorasLibres />
                    </PrivateRoute>
                    {/*<PrivateRoute path="/colectivo">
                        <Colectivo />
    </PrivateRoute>*/}
                    <PrivateRoute path="/verAmigos">
                        <VerAmigos />
                    </PrivateRoute>
                    <PrivateRoute path="/configuracion">
                        <Configuracion />
                    </PrivateRoute>
                    <PrivateRoute path="/home">
                        <Home />
                    </PrivateRoute>
                    <PrivateRoute path="/notifications">
                        <Notifications />
                    </PrivateRoute>
                    <NotFound />
                </Switch>
                <Footer />
            </Router>

        </div>
    )
}

function PrivateRoute({ children, ...rest }) {
    let auth = authandler.verify()
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.auth === "yes" ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/denied",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    )
}
function PrivateRouteFirstView({ children, ...rest }) {
    let auth = authandler.verify()
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.auth !== "yes" ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/home",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    )
}
export default App