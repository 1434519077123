import React from 'react'
import { FormControl, Button } from 'react-bootstrap';
import '../styles/cambiarPass.css'
export default class CambiarUsuario extends React.Component {
    render() {
        return (
            <div>
                <div className="conTitulo">
                    <h4>Cambiar usuario</h4>
                </div>
                <div className="containerMiniForm">
                    <div style={{ paddingBottom: 10 }}>
                        <FormControl
                            placeholder=" Digita tu contraseña"
                            aria-label="Contraseña"
                            aria-describedby="Contraseña"
                            type="password"
                        />
                    </div>
                    <div style={{ paddingBottom: 10 }}>
                        <FormControl
                            placeholder="Nuevo usuario"
                            aria-label="Nuevo usuario"
                            aria-describedby="Nuevo usuario"
                        />
                    </div>
                    <div className="containerBotonMiniForm">
                        <Button variant="primary">Aceptar</Button>
                    </div>
                </div>
            </div>
        )
    }
}