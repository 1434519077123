import React from 'react'
import '../styles/view404.css'
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom"
export default function Bienvenido() {
    return (
        <div className="todo404">
            <div className="containerH1Style">
                <h1 className="h1Style">¡Bienvenido, ya eres parte de holu!</h1>
            </div>
            <div className="iconoDeHoluContainerBienvenido">
                <img src={require('../assets/holu-logo-icono-blanco.png')} className="iconoDeHoluBienvenido" alt=""></img>
            </div>
            <div className="botonContainer404">
                <Link to="/">
                    <Button variant="light" style={{ color: '#037BFF' }} >Ir a holu</Button >
                </Link>
            </div>
        </div>
    )
}