import React from 'react'
import '../styles/firstView.css'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { Password } from 'primereact/password'
import Left from '../components/firstViewLeft'
import { Link, Redirect } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../connectionApi'
import authandler from '../authandler'
const letters = /^[A-Za-z]+$/
class FirstView extends React.Component {
    state = {
        user: "",
        pass: "",
        toHome: false,
        loading: false
    }
    async componentDidMount() {

    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handlerSignIn()
        }
    }
    notify = (mensaje) => toast(mensaje)
    handlerSignIn = async () => {
        this.setState({ loading: true })
        if (this.state.user.match(letters) && this.state.pass.pass.length > 5 && this.state.user.trim().length > 0) {//verificación si está registrado y todo bien
            let x = await api.login(this.state.user.trim().toLowerCase(), this.state.pass)
            if (x) {
                if (x.error) {
                    if (x.body === "unathorized1") {
                        this.setState({ loading: false })
                        this.notify("¡Usuario o contraseña incorrectos!")
                    } else {
                        this.setState({ loading: false })
                        this.notify("¡Debes terminar el proceso de registro, con el link que te enviamos a tu correo!")
                    }
                } else {//si autorizaron
                    authandler.sign(this.state.user)//user sin @
                    this.setState({ toHome: true, loading: false })
                }
            } else {
                this.notify("¡Algo salió mal, intenta de nuevo en unos minutos!")
                this.setState({ loading: false })
            }
        } else {
            if (this.state.pass.pass) {
                if (this.state.pass.pass.length <= 5) {
                    this.setState({ loading: false })
                    this.notify("¡La contraseña tiene por los menos 6 caracteres!")
                } else {
                    this.setState({ loading: false })
                    this.notify("¡Usuario o contraseña inválidos!")
                }
            } else {
                this.setState({ loading: false })
                this.notify("¡No dejes ningún campo vacío!")
            }
        }

    }
    onChangeUser = (value) => {
        this.setState({ user: value })
    }
    onChangePass = (value) => {
        this.setState({ pass: value })
    }
    render() {
        if (this.state.toHome) {
            return <Redirect to='/home' />
        }
        return (
            <div className="general">
                <ToastContainer />
                <Container fluid className="generalContainer">
                    <Row className="h-100">
                        <Col className="firstColumn" xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Left></Left>
                        </Col>
                        <Col className="secondColumn" xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="containerForm">
                                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                    <input type="text" className="form-control" placeholder="Usuario uninorte" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { this.onChangeUser(e.target.value) }} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">@uninorte.edu.co</span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 10 }}>
                                    <Password value={this.state.value} className="form-control" placeholder="Contraseña de holu" onChange={(e) => this.onChangePass({ pass: e.target.value })} onKeyDown={this._handleKeyDown} />
                                </div>
                                <div style={{ paddingBottom: 15 }}>
                                    <Link to="/newPass" >
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </div>
                                <div className="containerBoton">
                                    {this.state.loading ?
                                        <Button variant="primary" className="botonEstilo" onClick={() => this.handlerSignIn()} >Verificando datos...</Button>
                                        :
                                        <Button variant="primary" className="botonEstilo" onClick={() => this.handlerSignIn()} >Iniciar sesión</Button>

                                    }
                                </div>
                                <div className="containerFinal">
                                    <div className="unete">
                                        <h4>¡Únete hoy a holu!</h4>
                                    </div>
                                    <div className="containerBoton">
                                        <Link to="/register">
                                            <div>
                                                <Button className="botonEstilo" size="lg">Regístrate</Button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default FirstView