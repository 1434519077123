class Handler {
    jwt() {
        if (process.env.REACT_APP_JTOKEN) {
            return process.env.REACT_APP_JTOKEN
        } else {
            return "secretToken"
        }
    }
    server() {
        if (process.env.REACT_APP_API_URL) {
            return process.env.REACT_APP_API_URL
        }
    }
    crypt() {
        if (process.env.REACT_APP_CKEY) {
            return process.env.REACT_APP_CKEY
        } else {
            return "secretCKEY"
        }
    }
}
export default new Handler()