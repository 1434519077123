import React from 'react'
import Navbar from '../components/appNavbar'
import '../styles/horasLibres.css'
import '../styles/configuracion.css'
import '../styles/notifications.css'
import shortid from 'shortid'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { Button, Spinner } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from '../connectionApi'
//4j the best team ever, thanks for the support
class Notifications extends React.Component {
    state = {
        notificaciones: [],
        loading: false
    }
    async componentDidMount() {
        this.setState({ loading: true })
        let s = await api.retreiveFriendRequests()
        this.setState({ notificaciones: s.solicitudes, loading: false })
    }
    notify = (value, user) => {//0 para eliminar, 1 para aceptar
        if (value === 0) {
            toast("Se ha rechazado la solicitud de amistad de: " + user)
        } else {
            toast("Se ha aceptado la solicitud de amistad de: " + user)
        }
    }
    acceptRequest = async (user) => {
        let noti = this.state.notificaciones
        this.setState({ loading: true })
        let x = await api.aceptarSolicitud(user)
        if (x === "Done!") {
            for (let i = 0; i < noti.length; i++) {
                if (noti[i] === user) {
                    noti.splice(i, 1)
                    i = noti.length
                    this.setState({ notificaciones: noti, aceptado: user })
                }
            }
            this.notify(1, user)
        }
        this.setState({ loading: false })

    }
    rejectRequest = async (user) => {
        let noti = this.state.notificaciones
        this.setState({ loading: true })
        let x = await api.rechazarSolicitud(user)
        if (x === "Done!") {
            for (let i = 0; i < noti.length; i++) {
                if (noti[i] === user) {
                    noti.splice(i, 1)
                    i = noti.length
                    this.setState({ notificaciones: noti, eliminado: user })
                }
            }
            this.notify(0, user)
        }
        this.setState({ loading: false })
    }
    render() {
        return (
            <div className="generalHorasLibres">
                <ToastContainer />
                <div className="navbarContainerHorasLibres">
                    <Navbar />
                </div>
                <div className="coreSectionHorasLibres">
                    <div className="seccionCore">
                        <div className="containerTituloIcono">
                            <NotificationsNoneIcon style={{ color: '#FFFFFF', fontSize: 45 }} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', color: '#037BFF', paddingTop: 10 }}>
                            <h2>Solicitudes de amistad</h2>
                        </div>
                        <div className="laLista">

                            {this.state.loading ?
                                <div style={{ color: '#037BFF', display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                                    <Spinner animation="border" role="status" style={{ color: '#037BFF' }}></Spinner>
                                </div>
                                :
                                this.state.notificaciones.length > 0 ?
                                    <div style={{ maxHeight: '35vh' }} id="barra">
                                        {this.state.notificaciones.map(dato =>

                                            <div className="listItemHoraNotification" key={shortid.generate()}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <p className="wordsListItem" >{dato}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: 35, marginLeft: 35, paddingBottom: 10 }}>
                                                    <Button variant="success" size="sm" onClick={() => { this.acceptRequest(dato) }}>Aceptar</Button>
                                                    <Button variant="danger" size="sm" onClick={() => { this.rejectRequest(dato) }}>Rechazar</Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}> <h4>no tienes notificaciones</h4> </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default Notifications