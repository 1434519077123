import React from 'react'
import Navbar from '../components/appNavbar'
import '../styles/horasLibres.css'
import { Button, ButtonGroup, Spinner } from 'react-bootstrap'
import { IconButton, Avatar } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import shortid from 'shortid'
import api from '../connectionApi'
class HorasLibres extends React.Component {
    state = {
        horaSeleccionada: false,
        dia: 0,
        loading: false,
        horario: [],
        clasesDia: [],
        valueHoraSeleccionada: 0,
        amigosHora: []
    }
    handlerDia = (index) => {
        if (this.state.dia !== index) {
            this.setState({ dia: index, clasesDia: this.state.horario[index] })
        }
        this.setState({ horaSeleccionada: false })
    }
    seleccionarHora = (hora) => {
        this.setState({ horaSeleccionada: true, valueHoraSeleccionada: hora })
        switch (hora) {
            case "6:30 AM - 7:30 AM":
                this.setState({ amigosHora: this.state.clasesDia[0].amigos })
                break
            case "7:30 AM - 8:30 AM":
                this.setState({ amigosHora: this.state.clasesDia[1].amigos })
                break
            case "8:30 AM - 9:30 AM":
                this.setState({ amigosHora: this.state.clasesDia[2].amigos })
                break
            case "9:30 AM - 10:30 AM":
                this.setState({ amigosHora: this.state.clasesDia[3].amigos })
                break
            case "10:30 AM - 11:30 AM":
                this.setState({ amigosHora: this.state.clasesDia[4].amigos })
                break
            case "11:30 AM - 12:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[5].amigos })
                break
            case "12:30 PM - 1:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[6].amigos })
                break
            case "1:30 PM - 2:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[7].amigos })
                break
            case "2:30 PM - 3:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[8].amigos })
                break
            case "3:30 PM - 4:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[9].amigos })
                break
            case "4:30 PM - 5:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[10].amigos })
                break
            case "5:30 PM - 6:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[11].amigos })
                break
            case "6:30 PM - 7:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[12].amigos })
                break
            case "7:30 PM - 8:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[13].amigos })
                break
            case "8:30 PM - 9:30 PM":
                this.setState({ amigosHora: this.state.clasesDia[14].amigos })
                break
            default:
                break
        }
    }
    async componentDidMount() {
        this.setState({ loading: true })
        const horario = await api.traerHorasLibres()
        this.setState({ loading: false, horario: horario, clasesDia: horario[0] })
    }
    render() {
        return (
            <div className="generalHorasLibres">
                <div className="navbarContainerHorasLibres">
                    <Navbar />
                </div>
                <div className="coreSectionHorasLibres">
                    <div className="seccionUtil">
                        <div className="dia">
                            <ButtonGroup className="buttonGroupContainer">
                                {(this.state.dia === 0) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(0) }}>Lunes</Button> : < Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(0) }}>Lunes</Button>}
                                {(this.state.dia === 1) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(1) }}>Martes</Button> : <Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(1) }}>Martes</Button >}
                                {(this.state.dia === 2) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(2) }}>Miércoles</Button> : < Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(2) }}>Miércoles</Button>}
                                {(this.state.dia === 3) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(3) }}>Jueves</Button> : < Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(3) }}>Jueves</Button>}
                                {(this.state.dia === 4) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(4) }}>Viernes</Button> : < Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(4) }}>Viernes</Button>}
                                {(this.state.dia === 5) ? <Button style={{ color: 'white', borderColor: '#037BFF', backgroundColor: '#037BFF' }} onClick={() => { this.handlerDia(5) }}>Sábado</Button> : < Button variant="outline-primary" style={{ borderColor: '#037BFF' }} onClick={() => { this.handlerDia(5) }}>Sábado</Button>}
                            </ButtonGroup>
                        </div>
                        {(!this.state.horaSeleccionada) ?
                            <div className="hora" id="barra">
                                {this.state.loading ?
                                    <div style={{ color: '#037BFF', display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                                        <Spinner animation="border" role="status" style={{ color: '#037BFF' }}></Spinner>
                                    </div>
                                    :
                                    <>
                                        {this.state.clasesDia.map(obj =>
                                            <div key={shortid.generate()} className="listItemHora" onClick={() => { this.seleccionarHora(obj.hora) }}>
                                                <div style={{ display: 'flex', width: '100%', paddingLeft: '2%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h6 className="textosH6">{obj.hora}</h6>
                                                    </div>

                                                </div>
                                                <div>
                                                    {
                                                        obj.libre ?
                                                            <p style={{ color: 'green' }}>(Libre)</p>
                                                            :
                                                            <p style={{ color: 'red' }}>(Clase)</p>
                                                    }
                                                </div>
                                                <div>
                                                    <NavigateNextIcon />
                                                </div>

                                            </div>
                                        )}
                                    </>
                                }


                            </div>
                            :
                            <React.Fragment>
                                <div className="indicadorDiaContainer">
                                    <div>
                                        <h6 className="textos2H6">{this.state.valueHoraSeleccionada}</h6>
                                    </div>
                                    <div>
                                        <IconButton color="primary" aria-label="directions" style={{ color: '#037BFF' }} onClick={() => { this.setState({ horaSeleccionada: false }) }}>
                                            <CancelIcon />
                                        </IconButton>
                                    </div>

                                </div>
                                <div className="containerAmigos" id="barra">
                                    {this.state.amigosHora.length === 0 ?
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <span style={{ fontFamily: "Andale Mono, monospace", fontSize: 20 }}>No tienes amigos libres</span>
                                        </div>
                                        : this.state.amigosHora.map(obj =>

                                            <div className="listItem">
                                                <div style={{ display: 'flex', width: '90%' }}>
                                                    <div className="contenedorAvatar2">
                                                        <Avatar alt="">UN</Avatar>
                                                    </div>
                                                    <div className="containerP">
                                                        <span style={{ fontFamily: "Andale Mono, monospace", fontSize: 20 }}>{obj}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="dotContainer">
                                                        <span className="dot"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div >
        )
    }
}
export default HorasLibres