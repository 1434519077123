
import React from 'react';
import '../styles/home.css'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import DateRangeIcon from '@material-ui/icons/DateRange'
//import CommuteIcon from '@material-ui/icons/Commute'
import { Link } from "react-router-dom"
export default function ContainerBotonesHome() {
    return (
        <div className="otroDivContainer">
            <div className="containerFirstButton">
                <Link to="/horasLibres">
                    <div className="divText" >
                        <div>
                            <DateRangeIcon style={{ color: '#037BFF' }} />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <h5 style={{ fontFamily: "Andale Mono, monospace", color: '#037BFF' }}>amigos libres</h5>
                        </div>
                    </div>
                </Link>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                </div>
            </div>
            {/*<div className="containerSecondButton">
                <Link to="/colectivo">
                    <div className="divText" >
                        <div>
                            <CommuteIcon style={{ color: '#037BFF' }} />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <h5 style={{ fontFamily: "Andale Mono, monospace", color: '#037BFF' }}>Haz tu colectivo</h5>
                        </div>

                    </div>
                </Link>

    </div>*/}
            <div /*className="containerThirdButton"*/ className="containerSecondButton">
                <Link to="/verAmigos">
                    <div className="divText" >
                        <div>
                            <PeopleOutlineIcon style={{ color: '#037BFF' }} />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <h5 style={{ fontFamily: "Andale Mono, monospace", color: '#037BFF' }}>Ver  mis amigos</h5>
                        </div>

                    </div>
                </Link>
            </div>
        </div>
    )
}
