import React from 'react'
import Button from 'react-bootstrap/Button';
import '../styles/cambiarPass.css'
import api from '../connectionApi'
import { ToastContainer, toast } from 'react-toastify';
export default class CambiarPass extends React.Component {
    state = {
        email: "",
        loading: false
    }
    onChangeEmail(text) {
        this.setState({ email: text })
    }
    notify = (mensaje) => {
        toast(mensaje)
    }
    async onSend() {
        try {
            if (/^[a-zA-Z]+$/.test(this.state.email.trim()) && this.state.email.trim().length > 2) {//solo letras
                this.setState({ loading: true })
                await api.CambiarPass2(this.state.email.trim())
                this.setState({ loading: false })
                this.notify("El link para cambiar tu contraseña de holu a sido enviado con éxito.")
            } else {
                this.notify("Correo inválido, recuerda solo poner tu usuario de Uninorte.")
            }
        } catch (err) {
            this.setState({ loading: false })
            this.notify("Hubo un error al enviar el correo.")
        }

    }
    render() {
        return (
            <div>
                <ToastContainer />
                <div className="conTitulo">
                    <h4>Reestablecer contraseña</h4>
                </div>
                <div className="containerMiniForm">
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                        <input type="text" style={{ minWidth: 130 }} className="form-control" placeholder="Usuario uninorte" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { this.onChangeEmail(e.target.value) }} />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">@uninorte.edu.co</span>
                        </div>
                    </div>
                    {
                        this.state.loading ?
                            <div className="containerBotonMiniForm">
                                <Button variant="primary" onClick={() => this.onSend()}>Enviando...</Button>
                            </div>
                            :
                            <div className="containerBotonMiniForm">
                                <Button variant="primary" onClick={() => this.onSend()}>Enviar link</Button>
                            </div>
                    }

                </div>
            </div>
        )
    }
}