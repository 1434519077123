import React from 'react';
import { Row, Col, Container, Button, Spinner } from 'react-bootstrap'
import Left from '../components/firstViewLeft'
import { Password } from 'primereact/password';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch';
import api from '../connectionApi'
import { Link } from "react-router-dom"
export default class Register extends React.Component {
    state = {
        userName: "",
        passUni: "",
        nombre: "",
        passHolu: "",
        repeat: "",
        loading: false,
        checked: false
    }
    onChangeUser = (value) => {
        this.setState({ userName: value })
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }
    handleChange = () => {
        this.setState({ checked: !this.state.checked })
    }
    onChangePass = (value) => {
        this.setState({ passUni: value })
    }
    onChangeName = (value) => {
        this.setState({ nombre: value })
    }
    onChangeHoluPass = (value) => {
        this.setState({ passHolu: value })
    }
    onChangeRepeat = (value) => {
        this.setState({ repeat: value })
    }
    onSubmit = async () => {
        if (this.state.passHolu.passHolu === this.state.repeat.repeat && this.state.passHolu.passHolu.length >= 6 && this.state.userName.length > 0 && this.state.nombre.length > 0 && this.state.passUni.passUni.length > 0 && this.state.checked && this.state.passUni.passUni !== this.state.passHolu.passHolu) {
            this.setState({ loading: true })
            let response = await api.register(this.state.userName.toLowerCase(), this.state.passUni.passUni, this.state.nombre, this.state.passHolu.passHolu)
            this.setState({ loading: false })
            if (response === "DONE!") {
                let msg = "Confirma el correo enviado a: " + this.state.userName.toLowerCase() + "@uninorte.edu.co para que puedas ingresar a holu"
                document.getElementById('1').value = ''
                document.getElementById('2').value = ''
                document.getElementById('3').value = ''
                document.getElementById('4').value = ''
                document.getElementById('5').value = ''
                this.setState({ checked: false })
                toast(msg)
            } else {
                if (response) {
                    if (response.code) {
                        if (response.code === "auth/email-already-in-use") {
                            let error = "¡Este email ya ha sido registrado!"
                            toast(error)
                        } else {
                            if (response.code === "auth/too-many-requests") {
                                let error = "¡Haz excedido el número de intentos, intentalo de nuevo más tarde!"
                                toast(error)
                            } else {
                                if (response.code === "auth/wrong-password") {
                                    let error = "¡Contraseña incorrecta o cuenta no confirmada, recuerda confirmar tu cuenta con el enlace enviado a tu correo uninorte!"
                                    toast(error)
                                } else {
                                    let error = "¡Ocurrió un error, intentalo de nuevo más tarde!"
                                    toast(error)
                                }
                            }
                        }
                    } else {
                        if (response === "ErrorConnection") {
                            let error = "¡Tu contraseña o usuario de uninorte es inválida!"
                            toast(error)
                        } else {
                            if (response === "registeredBefore") {
                                let error = "Alguien ya está usando este correo."
                                toast(error)
                            } else {
                                if (response === "nombreEnUso") {
                                    let error = "Este nombre ya Está en uso, te aconsejo poner tus dos nombres y dos apellidos."
                                    toast(error)
                                } else {
                                    if (response === "nombreIncompleto") {
                                        let error = "Recuerda que debes poner tu nombre y tus dos apellidos."
                                        toast(error)
                                    } else {
                                        let error = "¡Ocurrió un error, intentalo de nuevo más tarde!"
                                        toast(error)
                                    }
                                }

                            }
                        }
                    }
                } else {
                    let error = "¡Ocurrió un error, intentalo de nuevo más tarde!"
                    toast(error)
                }


            }
        } else {
            if (this.state.passHolu.passHolu !== this.state.repeat.repeat) {
                let error = "¡Las contraseñas no coinciden!"
                toast(error)
            } else {
                if (this.state.passHolu.passHolu === this.state.repeat.repeat && this.state.repeat.repeat.length < 6) {
                    let error = "¡Tu contraseña para holu debe tener al menos 6 carácteres!"
                    toast(error)
                } else {
                    if (!this.state.checked) {
                        toast("Debes aceptar las políticas de tratamiento de datos.")
                    } else {
                        if (this.state.passHolu.passHolu === this.state.passUni.passUni) {
                            toast("No uses tu contraseña uninorte para registrarte en holu.")
                        } else {
                            let error = "¡Debes digitar datos válidos en cada uno de los campos!"
                            toast(error)
                        }
                    }
                }
            }
        }
    }
    render() {
        return (
            <div className="general">
                <ToastContainer />
                <Container fluid className="generalContainer">
                    <Row className="h-100">
                        <Col className="firstColumn" xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Left></Left>
                        </Col>
                        <Col className="secondColumn" xs={12} sm={12} md={8} lg={8} xl={8}>
                            <div className="containerForm">
                                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                                    <input id="1" type="text" className="form-control" placeholder="Usuario uninorte" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { this.onChangeUser(e.target.value) }} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">@uninorte.edu.co</span>
                                    </div>
                                </div>

                                <div className="password1Container">
                                    <Password id="2" value={this.state.value} className="form-control" placeholder="Contraseña uninorte" onChange={(e) => this.onChangePass({ passUni: e.target.value })} />
                                </div>

                                <div className="inputUniversidad">
                                    <input id="3" type="text" className="form-control" placeholder="Nombre y apellidos" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => { this.onChangeName(e.target.value) }} />
                                </div>

                                <div className="password1Container">
                                    <Password id="4" value={this.state.value} className="form-control" placeholder="Contraseña para holu" onChange={(e) => this.onChangeHoluPass({ passHolu: e.target.value })} />
                                </div>
                                <div className="inputUniversidad">
                                    <Password id="5" value={this.state.value} className="form-control" placeholder="Repetir contraseña para holu" onChange={(e) => this.onChangeRepeat({ repeat: e.target.value })} onKeyDown={this._handleKeyDown} />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Link to="/politicas" style={{ fontWeight: 'bold' }}>
                                        Acepto políticas de tratamiento de datos
                                    </Link>
                                    <Switch
                                        checked={this.state.checked}
                                        onChange={() => { this.handleChange() }}
                                        style={{ color: '#037BFF' }}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </div>
                                {(this.state.loading) ?
                                    <>
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#037BFF' }}>
                                                <h5 style={{ textAlign: 'center' }}>Esto puede tomar hasta 15 segundos</h5>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <Spinner animation="border" role="status" style={{ color: '#037BFF' }}></Spinner>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="containerBoton">
                                            <Button variant="primary" className="botonEstilo" onClick={() => { this.onSubmit() }}>Registrarme</Button>
                                        </div>
                                        <Link to="/" style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                                            Iniciar sesión
                                        </Link>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}