import React from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom"
import '../styles/notLoggedIn.css'
export default function NotLogged() {
    return (
        <div className="todoNotLoggedIn">
            <div className="containerH1Style">
                <h1 className="h1Style">Debes iniciar sesión</h1>
            </div>
            <div className="botonContainerNotLoggedIn">
                <Link to="/">
                    <Button variant="light" style={{ color: '#037BFF' }}>Iniciar sesión</Button >
                </Link>
            </div>
        </div>
    )
}